// src/pages/HomePage.tsx

import React from 'react';
import {BulletinBoardContainer} from "../containers/BulletinBoardContainer";

export const BulletinBoardPage: React.FC = () => (
    <div>
        <BulletinBoardContainer />
    </div>
);
